import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";
import i18n from '@/apps/auth/main';

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: [
		// DASHBOARD
		{ path: '/', meta: { title: 'Anmelden' }, component: () => import('@/apps/auth/views/Login.vue') },
		{ path: '/sign-up', meta: { title: 'Registrieren' }, component: () => import('@/apps/auth/views/Login.vue') },
		{ path: '/recover', meta: { title: 'Passwort vergessen' }, component: () => import('@/apps/auth/views/Recover.vue') },
		{ path: '/logout', meta: { title: 'Abmelden' }, component: () => import('@/apps/auth/views/Logout.vue') },
		// UTILS
		{ path: '/choose-lang', meta: { title: 'Language' }, component: () => import('@/apps/auth/views/utils/ChooseLang.vue') },
		{ path: '/maintenance', meta: { title: 'Maintenance' }, component: () => import('@/apps/auth/views/utils/Maintenance.vue') },
		// 404
		{ path: '/:pathMatch(.*)*', meta: { title: 'Error 404' }, component: () => import('@/apps/auth/views/utils/PageError.vue') },
	],
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
		  return savedPosition;
		}
		return { top: 0 };
	  },
});

router.beforeEach((to, from, next) => {
	// Inject meta information
	const title: string = (to.meta.title as string) || 'ComingSoon';
	const description: string = (to.meta.description as string) || 'ComingSoon';
	const visible: boolean = to.meta.visible == false;

	document.title = title + ' | Key2Host';

	const descriptionElement = document.querySelector('head meta[name="description"]');
	if (descriptionElement) {
		descriptionElement.setAttribute('content', description);
	}

	let robotsElement = document.querySelector('head meta[name="robots"]');
	if (!robotsElement) {
		robotsElement = document.createElement('meta');
		robotsElement.setAttribute('name', 'robots');
		document.head.appendChild(robotsElement);
	}
	robotsElement.setAttribute('content', visible ? 'index, follow' : 'noindex, nofollow');

	// Language check and other logic...
	const langC = Cookies.get('lang');
	if (!langC && to.path !== '/choose-lang') {
		const browserLang = navigator.language;
		const supportedLangs = ['de', 'en'];
		const detectedLang = supportedLangs.find(lang => browserLang.startsWith(lang));

		if (detectedLang) {
			i18n.global.locale.value = detectedLang as 'de' | 'en';
		} else {
			next('/choose-lang');
		}
	} else {
		if (langC) {
			i18n.global.locale.value = langC as 'de' | 'en';
		}
	}

	next();
});

export default router;